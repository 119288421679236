import React from 'react';
import SEO from '../components/seo/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ViewOutcomesSlideshow from '../components/outcomesSlideshow/ViewOutcomesSlideshow';


export default function BusinessPark () { 

	const images = useStaticQuery(graphql`
    query {
      bkg: file(relativePath: {eq: "21st-centry-business-park/bkg--21st-centry-business-park-text.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      slideshow: allFile(filter: {relativeDirectory: {eq: "21st-centry-business-park"}, base: {regex: "/frg--21st-centry-business-park/"}}, sort: { fields: [base] }) {
        edges {
          node {
            id
            base
            childImageSharp {
              fixed(width: 1346, height: 1080, quality: 80) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }  
  `);

  const slideshowImages = images.slideshow.edges.map( ( { node } ) => 
    <Img fixed={ node.childImageSharp.fixed } key={ node.id } alt='business park image' />
  );

  return (
    <>
      <SEO title='21st Century Business Park' />
      <Img fixed={ images.bkg.childImageSharp.fixed } alt='business park' />
      <ViewOutcomesSlideshow images={ slideshowImages } />
    </>
  );

}
